@import '~antd/dist/antd.less';

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout {
  minHeight: 100vh;
}

.mainContent {
  margin: 0 30px;
  padding: 30px 0;
}

.site-page-header {
  border-bottom: 1px solid rgb(235, 237, 240);
  background-color: #fff;
}

.ant-layout-sider-collapsed {
  min-height: calc(100vh - 80px) !important;
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}


.ant-breadcrumb+.ant-page-header-heading {
  margin-top: 0 !important;
}

.symbol>img,
.ant-image-img {
  width: 100%;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 0.475rem;
}

.symbol.symbol-50px>img {
  width: 50px;
  height: 50px;
}

.product {
  width: 60px;
  height: 85px !important;
  object-fit: cover;
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #e4e6ef;
  border-radius: .42rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
}

.symbol.symbol-circle,
.symbol.symbol-circle>img,
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}

.ant-breadcrumb a,
.ant-breadcrumb .text-muted {
  color: #A1A5B7 !important;
  font-size: 12px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ecdfe9;
}

.ant-form-item-explain-error {
  font-size: 12px;
  margin-bottom: 7px;
  margin-top: 5px;
}

.ant-breadcrumb-separator {
  margin: 0 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-size-xs {
  font-size: .8rem;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-size-sm {
  font-size: .925rem;
}

.productRate {
  font-size: 16px !important;

  .ant-rate-star:not(:last-child) {
    margin-right: 4px !important;
  }
}
@primary-color: #fceef0;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 15px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 10px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@height-base: 48px;@height-lg: 56px;@height-sm: 40px;