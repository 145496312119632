@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap&subset=latin,latin-ext');

html {
    width: 100%;
    height: 100%;
    background: '#f8f8f8';
}

#root {
    height: 100%;
}

:not(i) {
    font-family: 'Poppins', sans-serif !important;
}

body {
    font-size: 0.8rem;
    font-weight: 400;
    color: '#3a3a3a';
    background: '#f8f8f8';

    &.background {
        height: 100%;

        main {
            margin: 0 !important;
            height: 100%;

            .container {
                height: 100%;
            }

            &.sub-hidden {
                margin-left: 90px + 60px;
            }

            &.main-hidden {
                margin-left: 60px;
            }
        }
    }
}

a {
    color: #3a3a3a !important;
    transition: color 200ms;
}

.text-muted {
    color: #909090 !important;
    font-size: 13px !important;
}

.filterBox {
    min-width: 250px;
    min-height: 100px;
    padding: 20px;
    background-color: #fceef0;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.filterBox .filterTitle {
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

}

.loader-spinner {
    top: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 100%, .5019607843137255);
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.ant-table-thead .ant-table-cell {
    background-color: #fceef0;
}


.ant-menu-item-active {
  color: #c6c6c6 !important;
}


.ant-select-item {
    min-height: 40px !important;
    line-height: 30px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #001529 !important;
    color: white !important;
}

.ant-pagination-total-text {
    width: 100%;
    text-align: right;
}

.profileAvatar {
    padding: 5px;
    margin: 5px;
    border: solid 1px #FFF;
}

.ant-layout-sider-light {
    flex: 0 0 40px !important;
    max-width: 40px !important;
    min-width: 150px !important;
    width: 150px !important;
}

.highlight-bottom-border > td {
    border-bottom: solid 1px black !important;
  }


  .ant-table-cell {
    border: 2px solid #200 !important;
 
    padding: 4px !important;
    font-size: 12px;
     /* Adjust thickness and color as needed */
  }

  .ant-table-tbody > tr:nth-child(n+1) > td:nth-child(n+3) {
    text-align: right !important;
}


 
.ant-table-summary > tr > td:nth-child(n+2){
    text-align: right !important;
}

.ant-table-thead > tr > th {
    text-align: center !important;
}
 
 .ant-select-selection-placeholder,
.ant-picker-input > input,
.ant-input-number-input-wrap > input,
.ant-select-selection-item,
.ant-select-item-option-content{
    font-size: 12px !important;
}

 label {
    font-size: 12px !important; /* İstediğiniz boyuta ayarlayın */
    /* İstediğiniz diğer stillendirmeleri ekleyin */
}




  .ant-table-tbody > tr > td {
    border: 2px solid #000; /* Adjust thickness and color as needed */
  }

  .ant-table-tbody  > tr > td:first-child {
    background-color: #fceef0; /* Adjust thickness and color as needed */
  }

  .custom-style {
    color: red; /* Veya istediğiniz başka bir stil */
  }

  .ant-btn-round.ant-btn-sm{
    color: black
  }
  
.summary-row td:first-child{
    background-color : #fceef0
}

.ant-picker-content th:last-child {
  color: red;
}

.ant-picker-content th:nth-last-child(2) {
    color: blue; /* veya istediğiniz diğer renk kodu */
  }

  .pinkClass {
    background-color: #f2a3a2;
  }


  .greenClass {
    background-color: #6AC871;
  }